import { PublicClientApplication } from "@azure/msal-browser";

const config = {
	auth: {
		clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
		authority: process.env.VUE_APP_MSAL_AUTHORITY,
		knownAuthorities: [],
		redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI, //defaults to application start page
		postLogoutRedirectUri: process.env.VUE_APP_MSAL_POST_LOGOUT_URI,
	},
};

const msalClient = new PublicClientApplication(config);

export default msalClient;
