<template>
	<div id="login" style="min-height: calc(100vh - 20px)" class="fill-height d-flex align-items-center justify-content-center" tag="section">
		<b-row class="justify-content-center no-gutters">
			<b-col lg="9" md="9" class="d-flex align-items-center">
				<b-card class="w-100" style="min-width: 400px;" no-body>
					<div class="p-4">
						<img style="width: 100px;" src="@/assets/images/TOTAL-Logotype-Teal.png" />
						<h2 class="font-weight-bold mt-4">Sign in</h2>
						<h6 class="mb-4">
							Don't have an account? Sign Up
						</h6>

						<b-form @submit.prevent="login">
							<b-form-group>
								<b-form-input
									id="txt-username"
									type="text"
									placeholder="Enter Username"
									class="mb-1"
									v-model="form.username"
									:state="nameState"
									@blur="validateUser"
								></b-form-input>
								<b-form-invalid-feedback id="txt-username">This is a required field</b-form-invalid-feedback>
							</b-form-group>
							<b-form-group>
								<b-form-input
									id="txt-pwd"
									type="password"
									placeholder="Enter Password"
									class="mb-1"
									v-model="form.pwd"
									:state="pwdState"
									@blur="validatePwd"
								></b-form-input>
								<b-form-invalid-feedback id="txt-pwd">This is a required field</b-form-invalid-feedback>
							</b-form-group>

							<!-- Botón de inicio de sesión con Microsoft -->
							<b-button size="lg" variant="light" block class="mt-4" @click="signinWithPopup">
								Sign in with Microsoft
							</b-button>
							<b-button type="submit" variant="primary" size="lg" block class="mt-4" :disabled="!nameState && !pwdState">Sign In</b-button>
						</b-form>
					</div>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import msalClient from "../msal";
export default {
	name: "BoxedLogin",
	data: () => ({
		form: {
			username: "",
			pwd: "",
		},
		checkbox: false,
		nameState: null,
		pwdState: null,
	}),
	computed: {},
	methods: {
		async login() {
			if (this.form.username == "") {
				this.nameState = false;
				return;
			}
			if (this.form.pwd == "") {
				this.pwdState = false;
				return;
			}
			let data = {
				username: this.form.username,
				password: this.form.pwd,
			};
			const res = await this.$store.dispatch("auth/login", data);
			if (res) {
				this.$router.push("/home");
			}
		},
		validateUser() {
			if (this.form.username == "") {
				this.nameState = false;
				return;
			}
			this.nameState = true;
		},
		validatePwd() {
			if (this.form.pwd == "") {
				this.pwdState = false;
				return;
			}
			this.pwdState = true;
		},
		async signinWithPopup() {
			const loginRequest = {
				scopes: ["User.ReadWrite"],
			};

			try {
				const response = await msalClient.loginPopup(loginRequest);
				if (response) {
					let data = {
						username: response.account.username,
						token: response.accessToken,
					};
					const res = await this.$store.dispatch("auth/signInWithSSO", data);
					if (res) {
						this.$router.push("/home");
					}
				}
			} catch (error) {
				this.$bvToast.toast(`Authentication Failed`, {
					title: "Error",
					toaster: "b-toaster-top-center",
					variant: "danger",
					solid: true,
				});
			}
		},
	},
};
</script>
